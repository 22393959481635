import ThemeProvider from "./theme";
import {Box, Link, Stack, Typography} from "@mui/material";
import paper from './assets/img/Paper_map-rafiki.svg'
import grupo from './assets/img/Grupo 9560.svg'
import pana from './assets/img/Dashboard-pana.svg'
import remote from './assets/img/Remote meeting-rafiki.svg'
import logo from './assets/img/logotipo.svg'
import logoWhite from './assets/img/logotipo1.svg'
import logoSmall from './assets/img/logotipo.png'
import cover from './assets/img/13e27151e8.png'
import cover2 from './assets/img/cover2.jpeg'
import asBlack from './assets/img/Icon awesome-app-store.svg';
import asWhite from './assets/img/Icon awesome-app-store-1.svg';
import gpWhite from './assets/img/Icon awesome-google-play-1.svg';
import gpBlack from './assets/img/Icon awesome-google-play.svg';
import yt from './assets/img/Icon awesome-youtube.svg';
import elipse2 from './assets/img/Elipse 2.png';
import elipse3 from './assets/img/Elipse 3.png';
import elipse5 from './assets/img/Elipse 5.png';




function App() {
  return (
    <ThemeProvider>

        <Stack position='relative'   width='100%' alignItems='center'>
            <Box  position='relative'  maxWidth='1366px' display={{xs: 'flex', md: 'none'}} flexDirection='row' justifyContent='flex-end' height='100%' width='100%'>
                <img style={{objectFit: 'scale-down', width: 'auto'}}  height='100%' src={cover2} alt=""/>
                <Box bgcolor='#F4F4F4' position='absolute' bottom={-1} width='100%' height='20%' sx={{clipPath: 'polygon(100% 0, 100% 100%, 0 100%)'}}/>
            </Box>
            <Stack top='25px' position='absolute' display={{xs: 'flex', md: 'none'}} flexDirection='row' alignItems='center' justifyContent='center'>
                <Box>
                    <img src={logoWhite} alt="logo"/>
                </Box>
            </Stack>
            <Box  px={{xs: 4, md: 2}} pt={{xs: '30px', md: '53px'}} pb={{xs: '50px', md: '110px'}} width='100%' maxWidth='1280px'>
                <Stack display={{xs: 'none', md: 'flex'}} mb='120px' flexDirection='row' alignItems='center' justifyContent='space-between'>
                    <Box>
                        <img src={logo} alt="logo"/>
                    </Box>
                    <Link target='_blank' href='https://calendly.com/orbis-social/15min' sx={{textDecoration: 'none'}}>
                        <Box sx={{cursor: 'pointer', width: 'max-content', border: '1px solid #FFFFFF'}} borderRadius={2} color='white' fontSize='24px' py={2} px={3} bgcolor='transparent' >Marcar reunião</Box>
                    </Link>
                </Stack>
                <Stack>
                    <Stack display={{xs: 'none', md: 'flex'}} mb={1} gap={3} flexDirection='row' alignItems='center'>
                        {[{src: asBlack, link: 'https://apps.apple.com/br/app/orbis-rede-geo-social/id1453025529'},
                            {src: gpBlack, link: 'https://play.google.com/store/apps/details?id=com.orbis.orbis&rdid=com.orbis.orbis'},
                            {src: yt, link: 'https://www.youtube.com/@orbis.social'}].map(({src, link}, index) => <Link href={link} key={index} target='_blank' sx={{textDecoration: 'none'}}><Box sx={{cursor: 'pointer'}} key={index}><img src={src} alt=''/></Box></Link>)}
                    </Stack>
                    <Typography mb={1} variant='h1' fontStyle={'italic'} maxWidth='552px' >Crie assinaturas para seu <br/> grupo de membros e encontros</Typography>
                    <Typography mb={3} variant='body1' color='#707070'>Monetize seu grupo e organize encontros</Typography>
                    <Link display={{xs: 'flex', md: 'block'}} target='_blank' href='https://calendly.com/orbis-social/15min' sx={{textDecoration: 'none', justifyContent: 'center'}}>
                        <Box sx={{cursor: 'pointer', width: 'max-content', marginBottom: {xs: 0, md: '110px'}}} borderRadius={2} color='white' fontSize='24px' py={2} px={3} bgcolor='#222222' >Quero participar!</Box>
                    </Link>
                    <Stack display={{xs: 'none', md: 'flex'}} flexDirection='row' alignItems='center' gap={1}>
                        <Stack flexDirection='row' alignItems='center'>
                            {[elipse2, elipse3, elipse5, elipse2].map((src, index) =>
                                <Box sx={{transform: `translateX(-${index * 50}%)`}} key={index}><img src={src} alt=""/></Box>
                            )}
                        </Stack>
                        <Typography sx={{transform: `translateX(-70px)`}} variant='body1' >+250 grupos monetizados</Typography>
                    </Stack>
                </Stack>
            </Box>
            <Box  maxWidth='1366px' display={{xs: 'none', md: 'flex'}} flexDirection='row' justifyContent='flex-end' zIndex={-1} top={0} height='100%' width='100%' position='absolute'>
                <img style={{objectFit: 'fill', width: 'auto'}}  height='100%' src={cover} alt=""/>
            </Box>
        </Stack>

        <Stack px={{xs: 4, md: 2}}  sx={{background: '#EBE9E9 0% 0% no-repeat padding-box'}} width='100%' alignItems='center'>
            <Stack pt={{xs: '18px', md: '80px'}} pb={{xs: '34px', md: '80px'}} flexDirection={{xs: 'column', md: 'row'}} alignItems='center' justifyContent='space-between' width='100%' maxWidth='1080px'>
                <Typography mb={{xs: '12px', md: 0}} fontWeight='400' variant='h3' ><span style={{fontWeight: 600}} >Baixe gratuitamente</span> para Android e iPhone</Typography>
                <Stack  flexDirection={{xs: 'column', md: 'row'}} gap={{xs: 1, md: 3}}>
                    <Link target='_blank' href='https://apps.apple.com/br/app/orbis-rede-geo-social/id1453025529' sx={{textDecoration: 'none'}}>
                        <Stack alignItems='center' gap={1} flexDirection='row' sx={{cursor: 'pointer', width: 'max-content'}} borderRadius={2} color='white' fontSize='24px' py={1} px={2} bgcolor='#222222' >
                            <Box><img src={asWhite} alt=""/></Box>
                            <Stack>
                                <Typography lineHeight={1} variant='body1'>Baixe na</Typography>
                                <Typography lineHeight={1} fontStyle={'italic'} variant='h3' >App Store</Typography>
                            </Stack>
                        </Stack>
                    </Link>
                    <Link target='_blank' href='https://play.google.com/store/apps/details?id=com.orbis.orbis&rdid=com.orbis.orbis' sx={{textDecoration: 'none'}}>
                        <Stack alignItems='center' gap={1} flexDirection='row' sx={{cursor: 'pointer', width: 'max-content'}} borderRadius={2} color='white' fontSize='24px' py={1} px={2} bgcolor='#222222' >
                            <Box><img src={gpWhite} alt=""/></Box>
                            <Stack>
                                <Typography lineHeight={1} variant='body1'>Baixe na</Typography>
                                <Typography lineHeight={1} fontStyle={'italic'} variant='h3' >Google Play</Typography>
                            </Stack>
                        </Stack>
                    </Link>
                </Stack>
            </Stack>
        </Stack>

        <Stack px={{xs: 4, md: 2}}  width='100%' alignItems='center' mt={{xs: '30px', md: '100px'}} mb={{xs: '30px', md: '70px'}}>
            <Box width='100%' maxWidth='1080px'>
                <Typography textAlign='center' mb={{xs: '6px', md: '45px'}} variant='h2' >
                    Na rede Geo-social Orbis você pode:
                </Typography>
                <Stack gap={{xs: '24px', md: '100px'}}>
                    <Stack alignItems='center' flexDirection={{xs: 'column', md: 'row'}} gap={2} justifyContent='space-between'>
                      <Box width='100%' maxWidth='500px'>
                          <img src={paper} alt='' />
                      </Box>
                        <Stack gap={1}>
                            <Typography variant='h3'>
                                Ter uma localização pra chamar de sua!
                            </Typography>
                            <Typography color='#707070' variant='body1'>
                                Marque a localização do encontro do seu grupo no mapa. Quanto mais membros fizerem check-in maior fica o circulo com o símbolo do seu grupo no mapa.
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack alignItems='center' flexDirection={{xs: 'column-reverse', md: 'row'}} gap={2} justifyContent='space-between'>
                        <Stack gap={1}>
                            <Typography variant='h3'>
                                Gerar Renda!
                            </Typography>
                            <Typography color='#707070' variant='body1'>
                                Crie assinaturas mensais recorrentes para os membros do seu grupo
                            </Typography>
                        </Stack>
                        <Box width='100%' maxWidth='500px'>
                            <img src={grupo} alt='' />
                        </Box>
                    </Stack>

                    <Stack alignItems='center' flexDirection={{xs: 'column', md: 'row'}} gap={2} justifyContent='space-between'>
                        <Box width='100%' maxWidth='551px'>
                            <img src={pana} alt='' />
                        </Box>
                        <Stack gap={1}>
                            <Typography variant='h3'>
                                Gerenciar seus ganhos
                            </Typography>
                            <Typography color='#707070' variant='body1'>
                                Monitore seus ganhos com um sistema otimizado dentro do Orbis
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack mt={{xs: '12px', md: 0}}>
                        <Typography textAlign='center' mb='50px' variant='h2'>Como usar?</Typography>
                        <Stack flexDirection={{xs: 'column', md: 'row'}} gap={2}>
                            {['https://www.youtube.com/embed/L72cf1-NGIo', 'https://www.youtube.com/embed/ewJhokurfhE'].map((src, index) =>
                                    <Box borderRadius={2} flex={{xs: 'unset', md: '1',}} position='relative' sx={{height: '300px'}}>
                                        <Box zIndex={-1}  top={0} position='absolute' width='100%' sx={{height: '300px',backgroundColor: '#E6E6E6'}}>

                                        </Box>
                                        <iframe key={index} width="100%" height="300" src={src}
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen/>
                                    </Box>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Stack>

        <Stack px={{xs: 4, md: 2}}  sx={{background: '#EBE9E9 0% 0% no-repeat padding-box'}} width='100%' alignItems='center'>
            <Stack pt={{xs: '18px', md: '80px'}} pb={{xs: '34px', md: '80px'}} flexDirection={{xs: 'column', md: 'row'}} alignItems='center' justifyContent='space-between' width='100%' maxWidth='1080px'>
                <Typography mb={{xs: '12px', md: 0}} fontWeight='400' variant='h3' ><span style={{fontWeight: 600}} >Baixe gratuitamente</span> para Android e iPhone</Typography>
                <Stack  flexDirection={{xs: 'column', md: 'row'}} gap={{xs: 1, md: 3}}>
                    <Link target='_blank' href='https://apps.apple.com/br/app/orbis-rede-geo-social/id1453025529' sx={{textDecoration: 'none'}}>
                        <Stack alignItems='center' gap={1} flexDirection='row' sx={{cursor: 'pointer', width: 'max-content'}} borderRadius={2} color='white' fontSize='24px' py={1} px={2} bgcolor='#222222' >
                            <Box><img src={asWhite} alt=""/></Box>
                            <Stack>
                                <Typography lineHeight={1} variant='body1'>Download on</Typography>
                                <Typography lineHeight={1} fontStyle={'italic'} variant='h3' >App Store</Typography>
                            </Stack>
                        </Stack>
                    </Link>
                    <Link target='_blank' href='https://play.google.com/store/apps/details?id=com.orbis.orbis&rdid=com.orbis.orbis' sx={{textDecoration: 'none'}}>
                        <Stack alignItems='center' gap={1} flexDirection='row' sx={{cursor: 'pointer', width: 'max-content'}} borderRadius={2} color='white' fontSize='24px' py={1} px={2} bgcolor='#222222' >
                            <Box><img src={gpWhite} alt=""/></Box>
                            <Stack>
                                <Typography lineHeight={1} variant='body1'>Download on</Typography>
                                <Typography lineHeight={1} fontStyle={'italic'} variant='h3' >Google Play</Typography>
                            </Stack>
                        </Stack>
                    </Link>
                </Stack>
            </Stack>
        </Stack>

        <Stack px={{xs: 4, md: 2}}  my={{xs: '50px', md: '140px'}} width='100%' alignItems='center'>
            <Box width='100%' maxWidth='1080px'>
                <Stack alignItems='center' flexDirection={{xs: 'column', md: 'row'}} gap={6}>
                    <Box width='100%' maxWidth='500px'>
                        <img src={remote} alt='' />
                    </Box>
                    <Stack flex={1} gap={1}>
                        <Typography variant='h2'>
                            Vamos Conversar?
                        </Typography>
                        <Typography mb={2} variant='body1'>
                            Quer saber como pode ganhar dinheiro com o Orbis? Marque uma conversa direto com o CEO, ele está ansioso para lhe ajudar.
                        </Typography>
                        <Link display={{xs: 'flex', md: 'block'}} target='_blank' href='https://calendly.com/orbis-social/15min' sx={{textDecoration: 'none', justifyContent: 'center'}}>
                            <Box sx={{cursor: 'pointer', width: 'max-content'}} borderRadius={2} color='white' fontSize='16px' py={2} px={3} bgcolor='#222222' >Marcar reunião</Box>
                        </Link>
                    </Stack>
                </Stack>
            </Box>
        </Stack>

        <Stack px={{xs: 4, md: 2}}  width='100%' alignItems='center'>
            <Box width='100%' maxWidth='1080px'>
                <Box width='100%' height='1px' bgcolor='#707070'/>
                <Stack py='50px' flexDirection='row' alignItems='center' justifyContent='space-between'>
                    <Box display={{xs: 'none', md: 'block'}}>
                        <img src={logo} alt="logo"/>
                    </Box>
                    <Box display={{xs: 'block', md: 'none'}}>
                        <img src={logoSmall} alt="logo"/>
                    </Box>
                    <Stack gap={3} flexDirection='row' alignItems='center'>
                        {[{src: asBlack, link: 'https://apps.apple.com/br/app/orbis-rede-geo-social/id1453025529'},
                            {src: gpBlack, link: 'https://play.google.com/store/apps/details?id=com.orbis.orbis&rdid=com.orbis.orbis'},
                            {src: yt, link: 'https://www.youtube.com/@orbis.social'}].map(({src, link}, index) => <Link href={link} key={index} target='_blank' sx={{textDecoration: 'none'}}><Box sx={{cursor: 'pointer'}} key={index}><img src={src} alt=''/></Box></Link>)}
                    </Stack>
                </Stack>
            </Box>
        </Stack>

    </ThemeProvider>
  );
}

export default App;
